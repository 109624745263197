// BASE
export const BASE_URL = "https://backend.develop.ikrb.vvsgkube.agifly.cloud";
// export const BASE_URL = "http://localhost:5001"


// AUTH
export const USER_LOGIN = 'USER_LOGIN';
export const AUTH_TOKEN = 'AUTH_TOKEN';
export const TOKEN_EXPIRE = 'TOKEN_EXPIRE';
export const USER_VIEW = 'USER_VIEW';
export const CHECK_EMAIL = 'CHECK_EMAIL';

// LOADER
export const SHOW_ERROR_MESSAGE = "SHOW_ERROR_MESSAGE ";
export const SHOW_SUCCESS_MESSAGE = "SHOW_SUCCESS_MESSAGE";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const HIDE_MESSAGE = "HIDE_MESSAGE";

// MAP
export const SELECTED_AREA = "SELECTED_AREA"
export const SEARCH_LEVEL = "SEARCH_LEVEL"
export const SELECTED_STREET_LAYER = "STREET_LAYER_TYPE"
export const SELECTED_SECTOR_LAYER = "SECTOR_LAYER_TYPE"
export const SIMULATION_PARAMS = "SIMULATION_PARAMS"
export const SIMULATION_STATUS_TOGGLE = "SIMULATION_STATUS_TOGGLE"
export const SIMULATION_HEAT_DEMAND = "SIMULATION_HEAT_DEMAND"
export const SIMULATION_HEAT_SUPPLY = "SIMULATION_HEAT_SUPPLY"
export const SELECTED_SIMULATION_DETAILS = "SELECTED_SIMULATION_DETAILS"
export const SIMULATION_CASE = "SIMULATION_CASE"
export const SELECTED_CLUSTER = "SELECTED_CLUSTER"
export const SELECTED_CLUSTER_GEOJSON = "SELECTED_CLUSTER_GEOJSON"
export const SELECTED_CLUSTER_TYPE = "SELECTED_CLUSTER_TYPE"
export const CHANGE_STATISTICAL_SECTOR_SELECTION_STATE = "CHANGE_STATISTICAL_SECTOR_SELECTION_STATE"
export const CHANGE_SELECTED_STATISTICAL_SECTOR = "CHANGE_SELECTED_STATISTICAL_SECTOR"

// CONTENT
export const HEADER_CONTENT = "HEADER_CONTENT";

// ZONING
export const ZONING_MUNICIPALITY = "ZONING_MUNICIPALITY";
export const ZONING_MUNICIPALITY_GEOMETRY = "ZONING_MUNICIPALITY_GEOMETRY";
export const ZONING_MUNICIPALITY_GEOMETRY_LOADING = "ZONING_MUNICIPALITY_GEOMETRY_LOADING";
export const UPLOADED_DOCUMENTS = "UPLOADED_DOCUMENTS";

// ECONOMIC VIABILITY
export const CHANGE_VIABILITY_STATE = "CHANGE_VIABILITY_STATE";
export const SHOW_CARDS = "SHOW_CARDS";

// SIMULATION STATUS
export const SIMULATION_STATUS = {
    NEW: "NEW",
    PARAM_ADDED: "PARAM_ADDED",
    LOADING: "LOADING",
    COMPLETE: "COMPLETE",
    ERROR: "ERROR",
}

// SIMULATION
export const ADD_SIMULATION = "ADD_NEW_SIMULATION"
export const DELETE_SIMULATION = "DELETE_SIMULATION"
export const UPDATE_SIMULATION = "UPDATE_SIMULATION"
export const UPDATE_SIMULATION_STATUS = "UPDATE_SIMULATION_STATUS"
export const UPDATE_SIMULATIONS_STATUS = "UPDATE_SIMULATIONS_STATUS"
export const UPDATE_SIMULATIONS_NAME = "UPDATE_SIMULATIONS_NAME"
export const RUN_SIMULATIONS = "RUN_SIMULATIONS"
export const UPDATE_NEW_SIMULATION_PARAM = "UPDATE_NEW_SIMULATION_PARAM"
export const ON_SUCCESS_SIMULATION_API = "ON_SUCCESS_SIMULATION_API"
export const ON_ERROR_SIMULATION_API = "ON_ERROR_SIMULATION_API"
export const REMOVE_ALL_SIMULATIONS = "REMOVE_ALL_SIMULATIONS"
export const CHECK_RERUN_SIMULATION = "CHECK_RERUN_SIMULATION"
export const SET_IS_DISABLE_SIMULATION_RERUN = "SET_IS_DISABLE_SIMULATION_RERUN"
export const ADD_RENOVATION_PLANS = "ADD_RENOVATION_PLANS"

// PRODUCT DEMO
export const SET_PRODUCT_DEMO_STATE = "SET_PRODUCT_DEMO_STATE"
export const ON_CHANGE_SIMULATION_STATE = "ON_CHANGE_SIMULATION_STATE"
export const ON_CHANGE_MUNICIPALITY_WFS_DATA = "ON_CHANGE_MUNICIPALITY_WFS_DATA"
export const ON_CHANGE_SIMULATION_ID = "ON_CHANGE_SIMULATION_ID"


// STATISTICAL SECTOR
export const SET_SELECTION = "SET_SELECTION"
export const RESET_SECTOR_SELECTOR = "RESET_SECTOR_SELECTOR"
export const SET_ALERT = "SET_ALERT"
